@tailwind base;
@tailwind components;
@tailwind utilities;
@import '@fortawesome/fontawesome-free/css/all.min.css';
/* In your CSS file (e.g., index.css) */
@media (min-width: 1024px) {
    .lg\:hidden {
      display: none;
    }
  
    .lg\:relative {
      position: relative;
    }
  
    .lg\:transform-none {
      transform: none;
    }
  
    .lg\:shadow-none {
      box-shadow: none;
    }
  }
  
  @font-face {
    font-family: 'HostGrotesk';
    font-weight: 400;
    font-style: normal;
    src: url('./assets/fonts/HostGrotesk-Regular.ttf') format('truetype');
  }
  
  @font-face {
    font-family: 'HostGrotesk';
    font-weight: 500;
    font-style: normal;
    src: url('./assets/fonts/HostGrotesk-Medium.ttf') format('truetype');
  }
  
  @font-face {
    font-family: 'HostGrotesk';
    font-weight: 600;
    font-style: normal;
    src: url('./assets/fonts/HostGrotesk-SemiBold.ttf') format('truetype');
  }
  
  @font-face {
    font-family: 'HostGrotesk';
    font-weight: 700;
    font-style: normal;
    src: url('./assets/fonts/HostGrotesk-Bold.ttf') format('truetype');
  }
  
  body {
    font-family: 'HostGrotesk',  'Helvetica Neue', sans-serif;
  }
  
  .font-host-grotesk {
    font-family: 'HostGrotesk', sans-serif;
  }